/* You can add global styles to this file, and also import other style files */

.is-invalid .ng-select-container{border-color: #F55252;}
.is-invalid .ql-toolbar{border-top-color: #F55252 !important;border-right-color: #F55252 !important;border-left-color: #F55252 !important;}
.is-invalid .ql-container{border-bottom-color: #F55252 !important;border-right-color: #F55252 !important;border-left-color: #F55252 !important;}

.ql-container{height: 200px !important;}
.pointer{cursor: pointer;}

input-tags .form-control{
    height: auto;
}

.ng2-tag-input{border-bottom-color: transparent !important;}

/* Spinner container */
.spinner-container{background-color:rgba(0,0,0, 0.7);display: flex; justify-content: center;align-items: center;position:fixed;top:0;bottom:0;left:0;right:0;z-index:999999;}
.spinner-border {border-width:0.4em;width: 50px;height: 50px;}

.element-disabled{color: lightgrey !important;}

.btn-clear {
	background-color: transparent !important;
	border-style: none !important;
	cursor: pointer;
}
  
.btn-clear:active,
.btn-clear:focus {
	outline-style: none !important;
	outline-color: transparent;
}
.btn-clear:disabled {
	color: lightgrey !important;
}
